.card-container{
    height: 21rem;
    width: 100%;
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    padding: 2.24rem 2rem;
    padding-bottom: 1.88rem;


    border-radius: 0.5rem;
    border: 1px solid #6CCEFF;
    background: #FFF;
    box-shadow: 0px 12px 20px 0px rgba(48, 185, 253, 0.19);
}
.address-section{
    width: 100%;
    height: 100%;
}

.booking-address{
    width: 100%;
    height: 100%;
    border: none;
    background-color: inherit;
    outline: none;

    color:  #666;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    resize: none;

}
.edit-pen{
    display: flex;
    justify-content: end;
    cursor: pointer;
}
.edit-pen-ar{
    justify-content: start;
}
.edit-pen svg{
    width: 20px;
    height: 20px;
} 