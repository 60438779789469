.notification-icon-container{
    display: flex;
    position: relative;
}
.notification-svg svg{
    width: 29px;
    height: 29px;
    cursor: pointer;

}
.notification-count{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
    /* padding: 0.2rem; */

    color: #FFF;
    text-align: center;
    font-size: 0.6875rem;
    font-weight: 400;
    background-color: #F80000;
}
.notification-count{
    position:absolute;
    left: 24px;
    bottom: 29px;
}


@media screen and (max-width:1200px){
    .notification-svg svg {
        width: 26px;
        height: 26px;
    }
}