.service-card-container{
    display: flex;
    justify-content: center;
    padding: 3.81rem 0rem;
    min-height: 40rem;
}
.service-card-box{
    width: 64.25rem;
    display: flex;
    flex-direction: column;
    gap: 0.81rem;
}

@media screen and (max-width:992px){
    .service-card-box{
        width: 52.25rem;
    }
}

@media screen and (max-width:768px){
    .service-card-box {
        width: 42.25rem;
    }
}

@media screen and (max-width:576px){
    .service-card-box {
        width: 35.25rem;
    }
}