.date-filter-container-ar{
    flex-direction: row-reverse;
}
.filter-by-date-box-ar{
    display: flex;
    gap: 0.4rem;
    flex-direction: row-reverse;
}
.date-picker-container-ar{
    right: 0rem;
    left: auto;
}
.button-section-ar{
    flex-direction: row-reverse;
}