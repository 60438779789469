.search-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    border-radius: 0.75em;
    border: 1px solid rgba(255, 255, 255, 0.50);
    background: linear-gradient(96deg, #166992 6.65%, #108AC6 95.48%);
    
}
.icon-search-container{
    display: flex;
    gap: 0.62em;
}
.search-input{
    width: 100%;
    height: 100%;
    background: inherit;
    border: none;
    margin: none !important;
    outline: none;
    color: #FFF;
    font-family: Poppins;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.search-input::placeholder{
    color: #FFF;
}
.search-svg-icon{
    display: flex;
    align-items: center;
    padding-left:0.5rem;

}
.search-svg-icon svg{
    width: 1.5em;
    height: 1.5em;
}
.search-button-container{
    display: flex;
    padding: 0.5em;
}
.search-button{
    border-radius: 0.375em;
    border: 1px solid #108AC6;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(48, 185, 253, 0.27);

    display: inline-flex;
    padding: 0.625em 1.75em;
    justify-content: center;
    align-items: center;
    gap: 1.4375em;
    cursor: pointer;
}