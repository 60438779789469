.image-tab-section-ar{
    order:2;
}
.nav-logo-img-ar{
    order: 2;
}
.tab-section-ar{
    flex-direction: row-reverse;
}
.notification-name-section-ar{
    flex-direction: row-reverse;
}
.my-profile-options-box-ar{
    justify-content: end;
    flex-direction: row-reverse;
}

@media screen and (max-width:1200px){
    .other-profile-options{
        left: 0.3rem;
        top: 4rem;
    }
}

@media screen and (max-width:992px){
    .image-tab-section-ar{
        flex-direction: column-reverse;
    }
    .tab-section-ar{
        flex-direction: column;
        align-items: flex-end;
    }
    .other-profile-options{
        top: 5.5rem;
    }
}