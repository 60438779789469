.banner-outer-container{
    width: 100%;
    display: flex;
    padding:0rem 14.06rem;
    height: 34.75rem;
    background: linear-gradient(96deg, #166992 6.65%, #108AC6 95.48%);
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.11);
}
.banner-inner-container{
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
}
.banner-inner-container-ar{
    justify-content: end;
}

.banner-text-container-left{
    font-size: 1rem;
}
.banner-text-container-left-ar{
    display: flex;
    flex-direction: column;
    align-items: end;
}

.search-box{
    width: 32.3125em;
    height: 3.6875em;
    margin-top: 2.52em;
    margin-bottom: 2.44em;
}

.banner-texts{
    display: flex;
    flex-direction: column;
    text-align: left;
}
.banner-texts-ar{
    text-align: right;
}
.banner-text-main-heading{
    display: flex;
    flex-direction: column;

}
.banner-text-main-heading :nth-child(1){
    color: #FFF;
    font-size: 2.5em;
    font-weight: 400;
    line-height: 143.5%; /* 3.5875rem */
}
.banner-text-main-heading :nth-child(2){
    color: #FFF;
    font-size: 2.75em;
    font-weight: 600;
    line-height: 143.5%;
}
.banner-text-sub-heading{
    display: flex;
    max-width: 50em;
    color: #FFF;
    font-size: 1em;
    font-weight: 400;
    margin-top: 0.37em;
}

.feature-container{
    display: flex;
    gap: 2em;
}
.feature-container-ar{
    flex-direction: row-reverse;
}
.feature-box{
    display: flex;
    align-items: center;
    gap: 0.06em;
}
.feature-box-ar{
    flex-direction: row-reverse;
}
.svgicon svg{
    width: 3.1875em;
    height:3.1875em;
}
.feature-text{
    display: flex;
    flex-direction: column;
    text-align: left;
}
.feature-text-ar{
    text-align: right;
}
.feature-text :nth-child(1){
    color: rgba(255, 255, 255, 0.70);
    font-size: 1.125em;
    font-weight: 500;
}
.feature-text :nth-child(2){
    color: rgba(255, 255, 255, 0.70);
    font-size: 0.875em;
    font-weight: 400;
}

.banner-image-section{
    display: flex;
    position: absolute;
    bottom: 0rem;
    right: 0rem;
}
.banner-image-section-ar{
    left: 0rem;
    right: unset !important;
}
.outer-circle-image{
    /* border-radius: 50.125rem; */
    background: rgba(48, 185, 253, 0.10);
    width: 50.125rem;
    height: 25.065rem;
    border-top-left-radius: 50.125rem;
    border-top-right-radius: 50.125rem;
    position: relative;
}
.inner-cicle-image{
    position: absolute;
    background: rgba(255, 255, 255, 0.25);
    width: 47.375rem;
    height: 23.5rem;
    border-top-left-radius: 47.375rem;
    border-top-right-radius: 47.375rem;
    bottom: 0rem;
    left: 50%;
    transform: translateX(-50%);
}

.banner-image{
    position: absolute;
    bottom: 0rem;
    left: 50%;
    transform: translateX(-50%);
}
.banner-image-ar{
    transform: rotateY(180deg);
    left: 10%;
    /* transform: translateX(0%); */
}


@media screen and (max-width:1800px) and (min-width:1600px){
    .banner-outer-container{
        padding: 0 8.06rem;
    }
}


@media screen and (max-width:1600px) and (min-width:1400px){
    .banner-outer-container{
        padding: 0 6.06rem;
    }
    .outer-circle-image{
        width: 48.125rem;
        height: 23.065rem;
    }
    .inner-cicle-image{
        width: 45.375rem;
        height: 21.5rem;
    }
    .banner-image{
        width: 37.5625rem;
    }
}

@media screen and (max-width:1400px) and (min-width:1200px){
    .banner-outer-container{
        padding: 0 2.06rem;
    }
    .inner-cicle-image{
        width: 43.375rem;
        height: 19.5rem;
    }
    .outer-circle-image{
        width: 46.125rem;
        height: 21.065rem;
    }
    .banner-image{
        width: 35.5625rem;
    }
    .banner-text-sub-heading{
        max-width: 39em;
    }
    .tab-grid-card-container{
        padding: 2rem 3.5rem;
    }
}

@media screen and (max-width:1200px){
    .banner-outer-container{
        padding: 0rem 2.06rem;
    }
    .outer-circle-image{
        width: 42.125rem;
        height: 17.065rem;
    }
    .inner-cicle-image{
        width: 39.375rem;
        height: 15.5rem;
    }
    .banner-image{
        width: 31.5625rem;
    }
    .banner-text-sub-heading{
        max-width: 34em;
    }
    .feature-container{
        gap: 1em;
    }
    .search-box{
        width: 29.3125em;
    }
}

@media screen and (max-width:992px){
    .banner-image-section{
        display: none;
    }
}


@media screen and (max-width:576px){
    /* .banner-text-container-left{
        font-size: 0.75rem;
    } */
    .banner-outer-container{
        padding: 0 2rem;
    }
    .banner-text-sub-heading{
        width: 100%;
    }
    .search-box {
        width: 100%;
    }
}