.service-card-main{
    display: flex;
    flex-direction: column;
    gap: 0.88rem;
    width: 15rem;
    min-height: 18.0625rem;
    border-radius: 0.5625rem;
    background: #FFF;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
    cursor: pointer;
}
.service-card-image-box{
    width: 100%;
    height: 9.9375rem;
}
.service-card-image-box img{
    width: 100%;
    height: 100%;
    border-radius: 0.5625rem 0.5625rem 0rem 0rem;
}
.service-card-texts{
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    padding: 0rem 1.81rem 0.8rem 0.94rem;
    text-align: left;
}
.service-heading{
    display: flex;
    justify-content: center;
    /* text-align: center; */
    color: #000;
    font-size: 0.9375rem;
    font-weight: 400;
}
.service-content{
    color:#666;
    font-size: 0.75rem;
    font-weight: 400;
}


@media screen and (max-width:576px){
    .service-card-main{
        width: 14rem;
    }
}
