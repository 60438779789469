.service-list-card-main-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    /* height: 8.25rem; */
    padding: 0.93rem 1.94rem;
    border-radius: 0.5625rem;
    background: #FFF;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.15);
}
.service-list-card-main-container-ar{
    flex-direction: row-reverse;
}
.image-title-container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.image-title-container-ar{
    flex-direction: row-reverse;
}
.service-list-image-box{
    width: 6.375rem;
    height: 6.375rem;
}
.service-list-image-box img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.service-list-detail-text{
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    justify-content: center;
    padding: 0.88rem;
    text-align: start;
}
.service-list-detail-text-ar{
    text-align: end;
}
.service-detail-heading{
    color: #000;
    font-size: 1.0625rem;
    font-weight: 400;
}
.service-detail-subheading{
    color: #666;
    font-size: 0.875rem;
    font-weight: 400;
}

@media screen and (max-width:768px){
    .service-list-image-box{
        width: 4.375rem;
        height: 4.375rem;
    }
}

