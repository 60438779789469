.new-box-style{
    justify-content: end;
    gap: 1.19rem;
}

.closeButton{
    position: absolute;
    top: 1.62rem;
    right: 2.56rem;
    cursor: pointer;
}