.lock-icon-container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.lock-icon-container-ar{
    padding-left: 0.5rem;
}
.lock-icon-container svg{
    width: 22px;
    height: 22px;
}
.eye-icon-container{
    display: flex;
    padding-left: 0.5rem;
    align-items: center;
    justify-content: center;
}
.eye-icon-container svg{
    cursor: pointer;
}
.yes-arabic{
    text-align: right;
}
.eye-icon-container-ar{
    padding-left: 0rem;
    padding-right: 0.5rem;
}