.tab-name-box{

    color: #000;
    font-size: 1rem;
    font-weight: 400;
    padding-bottom: 0.62rem;
    cursor: pointer;
}

.tab-name-box.selected{
    color: #108AC6;
    border-bottom: 3px solid #108AC6;
}