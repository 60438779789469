.search-container-secondary-ar{
    flex-direction: row-reverse;
}
.icon-search-container-ar{
    flex-direction: row-reverse;
}
.search-input-secondary-ar{
    direction: rtl;
    
}
.search-svg-icon-ar{
    padding-left: 0rem;
    padding-right: 0.5rem;
}