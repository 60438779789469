.dot-icon-image-box{
    position: relative;
}
.glitter-img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.message-box{
    display: flex;
    color:#666;
    text-align: center;
    font-size: 1.3125rem;
    font-weight: 500;
    max-width: 18rem;
    align-self: center;    
}
.success-message{
    display: flex;
    flex-direction: column;
    gap: 5rem;
}
.success-popup{
    height: 38.125rem;
    align-items: center;
    justify-content: center;
    display: flex;
}