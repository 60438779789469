
.date-box{
    display: flex;
    gap: 0.3rem;
}
.eye-detail-icon{
    padding-top: 2rem;
}
.eye-detail-icon svg{
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.dis-flex{
    display: flex;
}
.dis-gap{
    gap: 0.7rem
}
.dis-relative{
    position: relative;
}
.cus-padding{
    padding: 1.53rem 1.75rem;
    padding-right: 2.75rem;
}
.state-card-container{
    position:absolute;
    border-radius: 0rem 0.5rem 0rem 0rem;
    width: 8.75rem;
    height: 2.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0rem;
    top: 0rem;
    background: #FFDD80;
}
.pending{
    background: #FFDD80;
}
.accepted{
    background: #3E8424;
    color: #FFFFFF;
}
.under_process{
    background-color: rgb(50, 104, 68);
    color:#FFF;
}


@media screen and (max-width:768px){
    .dis-flex{
        flex-direction: column;
    }
}

