.service-bookings-main-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.service-bookings-sub-container{
    display: flex;
    width: 80%;
    gap: 0.57rem;
    margin-top: 1.06rem;
    padding-bottom: 0.87rem;
}
.service-booking-sidebar{
    display: flex;
    flex-direction: column;
    width: 16.4375rem;
    height: 100%;
    padding: 2.3125rem 1.6875rem 2.3125rem 1.5625rem;
    border-radius: 0.875rem;
    border: 0.6px solid #B0B0B0;
    background: #FFF;
}
.service-booking-right-section{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 0.19rem;
    gap: 1.63rem;
}
.service-booking-filter-section{
    display: flex;
    gap: 0.75rem;
}
.service-booking-list-section{
    display: flex;
    flex-direction: column;
    gap: 1.06rem;
}

@media screen and (max-width:1200px){
    .service-bookings-sub-container{
        width: 90%;
    }
}

@media screen and (max-width:992px){
    .service-bookings-sub-container{
        flex-direction: column;
    }
    .service-booking-sidebar{
        width: auto;
    }
}

@media screen and (max-width:768px){
    .service-booking-sidebar{
        overflow-x: scroll;
    }
}