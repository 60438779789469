.side-bar-filter-main{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.56rem;
    
}

@media screen and (max-width:992px){
    .side-bar-filter-main{
        flex-direction: row;
    }
}