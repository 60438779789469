.pagination-main-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    padding-top: 1.25rem;
    border-top: 1px solid #EAECF0;
}
.page-numbers-buttons{
    display: flex;
    gap: 0.3rem;
}
.previous-button,
.next-button,
.page-number {
    padding: 8px 16px;
    /* border: 1px solid #ccc; */
    border: none;
    background:  #FFF;
    cursor: pointer;
    border-radius: 0.5rem;
    color: #666;
    font-size: 0.875rem;
    font-weight: 500;
}
.previous-button,
.next-button{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.page-number.active {
    border-radius: 0.5rem;
    background: #108AC6;
    color: white;
}

.page-number:hover,
.previous-button:hover,
.next-button:hover {
    background-color: #007bff;
    color: white;
}
.previous-button:disabled,
.next-button:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.ellipsis {
    padding: 8px;
}