

.text-area-field-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.62rem;
    text-align: left;


}
.text-area-field-container label{
    color: #666;
    font-size: 0.875rem;
    font-weight: 400;
}

.text-area-field-container .text-area-box{
    width: 100%;
    height: 8.9375rem;
    padding: 0.94rem;
    border-radius: 0.5rem;
    border: 1px solid #B0B0B0;
}

.text-area-box-ar{
    display: flex;
    justify-content: end;
}
.yes-arabic{
    text-align: end;
}

.text-area-box textarea{
    height: 100%;
    display: flex;
    width: 16.1875rem;
    color: #000;
    font-size: 1rem;
    font-weight: 400;
    outline: none;
    border: none;
    font-family: Poppins;

}