.tab-container{
    width: 80rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.44rem;
    margin-bottom: 1.44rem;
}

.tab-switch-box{
    display: flex;
    gap: 1rem;
}

.tab{
    color: #000;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    position: relative;
    cursor: pointer;
}

/* .tab::after{
    content: '';
    width: 100%;
    height: 3px;
    background-color: blue;
    position: absolute;
    bottom: -10px;
} */

.tab-grid-card-container{
    width: 100%;
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
}

.tab.active {
    border-bottom: 2px solid #108AC6;
}
.tab.selected{
    border-bottom: 2px solid #108AC6;
    color: #108AC6;
    font-weight: 600;
}
.primary-button-box{
    margin-bottom: 6.19rem;
}


@media screen and (max-width:1200px){
    .tab-container{
        width: 70rem;
    }
}

@media screen and (max-width:992px){
    .tab-container{
        width: 50rem;
    }
}

@media screen and (max-width:768px){
    .tab-container{
        width: 40rem;
    }
}

@media screen and (max-width:576px){
    .tab-container{
        width: 30rem;
    }
}