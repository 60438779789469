/* font poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
    --primary-color:#108AC6;
    --subtext-colr:#666;
    --input-field-color:#B0B0B0;
    --error-message:red;
}

html{
    font-size: 16px;
}

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body{
    font-family: 'Poppins',serif;
}

.content-section{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3.06rem;
}

.heading-description{
    display: block;
    width: 36.375rem;
    color: var(--subtext-color);
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 1rem;
}
.m-0{
    margin:0rem;
}
.error-message{
    color: var(--error-message);
    font-size: 0.8125rem;
    font-weight: 400;
}
.arabic-flex{
    flex-direction: row-reverse;
}
.body-container{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.body-container:first-child{
    flex: 1;
}
.hide{
    display: none !important;
}

@media screen and (max-width:1600px){
    html {
        font-size: 15px;
    }
}

@media screen and (max-width:1200px){
    html {
        font-size: 14px;
    }
}

@media screen and (max-width:768px){
    html {
        font-size: 13px;
    }
}

@media screen and (max-width:576px){
    .heading-description{
        width:25rem
    }
    html{
        font-size: 12px;
    }
}
