.login-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.login-head-section{
    display: flex;
    justify-content: center;
    text-align: center;
    gap: 0.37rem;
    padding-bottom: 0.75rem;
}
/* .login-head-section :nth-child(1){
    color: #000;
    font-size: 2.0625rem;
    font-weight: 600;
} */
.login-head-section :nth-child(2){
    color: #000;
    font-size: 1.3125rem;
    font-weight: 400;
}
.login-popup{
    width: 39.5rem;
    /* height: 30.6875rem; */
    padding: 2.25rem 3.38rem;
    padding-bottom: 3.63rem;
    border-radius: 1.5rem;
    background: #FFF;
}
.login-head-section{
    display: flex;
    flex-direction: column;
}
.login-input-button-section{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.62rem;
}
.input-field-section{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.88rem;
}
.forgot-already-account-box{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 2.25rem;
    align-items: center;
}
.forgot-already-account-box-ar{
    flex-direction: row-reverse;
}
.forgot-pass-text{
    color:#108AC6;
    font-size: 0.8125rem;
    font-weight: 400;
    cursor: pointer;
}