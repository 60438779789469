.page-title-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.38rem;
    align-items: center;
    width: 100%;
    height: 10.625rem;
    background: rgba(198, 236, 255, 0.27);
    text-align: center;
}
.page-title{
    color: #000;
    font-size: 2.25rem;
    font-weight: 500;
}
.page-sub-title{
    color: #000;
    font-size: 1.375rem;
    font-weight: 400;
}
.page-text-content{
    color: #666;
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    max-width: 37.125rem;
}
.page-path-text-content{
    color: #666;
    font-size: 1.125rem;
    font-weight: 400;
}
.category-service-container{
    display: flex;
    justify-content: center;
}
.service-search-container{
    display: flex;
    justify-content: center;
    padding-top: 1.7rem;
}
.service-seach-box{
    width: 29.5625rem;
}

@media screen and (max-width:992px){
    .service-seach-box{
        width: 23.5625rem;
    }
}
@media screen and (max-width:768px){
    .page-title{
        font-size: 2rem;
    }
}
