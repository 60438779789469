.phone-input-wrapper{
    display: flex;
    flex-direction: column;
    text-align: left;
    /* gap: 0.87rem; */
    gap: 0.62rem;
    width: 100%;
}
.phone-input-wrapper label{
    color: var(--subtext-color,#666);
    font-size: 0.875rem;
    font-weight: 400;

}
.mobile-icon-container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.mobile-icon-container-ar{
    padding-left: 0.5rem;
}
.mobile-icon-container svg{
    width: 22px;
    height: 22px;
}
.phone-input-container{
    display: flex;
    padding: 0.2rem 0.81rem;
    border-radius: 0.5rem;
    border: 1px solid var(--input-field-color,#B0B0B0);
    width: 100%;
    height: 2.8125rem;
}
.phone-input-container-ar{
    flex-direction: row-reverse;
}
.phone-input-container input{
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    padding-left: 0.5rem;
    background-color: inherit;
}.yes-arabic{
    text-align: right;
}