.notification-box-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1.81rem;
    padding-right: 1.81rem;
    width: 100%;
    height: 6.25rem;

    border-radius: 0.5625rem;
    background: #FFF;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.15);


}
.notification-image-message-container{
    display: flex;
    gap: 1.87rem;
}
.notification-status-image{
    display: flex;
    align-items: center;
}
.notification-status-image img{
    width: 2.5625rem;
    height: 2.5625rem;
}
.notification-message{
    display: flex;
    align-items: center;
    max-width: 33.47rem;
    display: flex;
    text-align: start;

    color: #666;
    font-size: 1rem;
    font-weight: 400;
}
.notification-date{
    display: flex;
    align-items: center;
    color: #000;
    font-size: 0.875rem;
    font-weight: 400;
}

@media screen and (max-width:768px){
    .notification-image-message-container{
        gap: 0.87rem;
    }
    .notification-message{
        max-width: 26.47rem;
    }
}

@media screen and (max-width:576px){
    .notification-box-container{
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-end;
    }
}