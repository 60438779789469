

    .close-button-u {
        position: absolute;
        top: 1.56rem;
        right: 1.56rem;
        background: none;
        border: none;
        cursor: pointer;
    }
    .popup-image{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;

    }
    
    .popup-image img {
        width: 250px;
        height: auto;
    }
    
    .popup-text {
        color: #666;
        font-size: 1rem;
        text-align: center;
    }
    
    .popup-buttons {
        display: flex;
        flex-direction: column;
        gap: 0.88rem;
    }
    
    .create-account {
        background: #008BD2;
        color: white;
        padding: 12px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
    }
    
    .create-account:hover {
        background: #0071B3;
    }
    
    .login {
        background: white;
        color: #666;
        border: 1px solid #ccc;
        padding: 12px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
    }
    
    .login:hover {
        background: #f5f5f5;
    }
    .pop-up-gap{
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }