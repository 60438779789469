.category-grid-card{
    width: 15.0625rem;
    height: 10.5rem;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border-radius: 0.5625rem;
    background: #FFF;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 8, 0.15);
    margin-bottom: 0.8rem;
    cursor: pointer;
}

.category-grid-card img{
    width: 2.9375rem;
    height: 2.9375rem;
}

.category-name{
    color: #000;
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.category-grid-card span{
    font-size: 0.8125rem;
    color: var(--subtext-color);
}


@media screen and (max-width:576px){
    .category-grid-card{
        width: 14rem;
    }
}