.notification-main-container{
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 40rem;
    padding-bottom: 3rem;
}
.notification-container{
    display: flex;
    flex-direction: column;
    text-align: start;
    gap: 1.19rem;
    margin-top: 1.81rem;
}
.notification-day{
    color:#616161;
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 1.19rem;
}
.notification-sub-container{
    display: flex;
    flex-direction: column;
    gap: 0.94rem;
    width: 64.25rem;
    max-width: 64.25rem;
}

@media screen and (max-width:992px){
    .notification-sub-container{
        width: 51.25rem;
    }
}

@media screen and (max-width:768px){
    .notification-sub-container {
        width: 40.25rem;
    }
}

@media screen and (max-width:576px){
    .notification-sub-container {
        width: 32.25rem;
    }
}