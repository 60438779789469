.page-path{
    display: flex;
    gap: 0.1rem;
    font-size: 1.125rem;
}
.page-path span:nth-child(1){
    color: #666;
    font-weight: 400;
}
.page-path span:nth-child(2){
    color: #000;
    font-weight: 500;
    padding-right: 0.1rem;
}
.page-path span:nth-child(3){
    color: #000;
    font-weight: 500;
}
.service-card-container-2{
    display: flex;
    justify-content: center;
    padding-top: 1.88rem;
    padding-bottom: 1.62rem;
    margin-bottom:10rem;
}

.service-card-box-2{
    width: 66.5625rem;
    display: flex;
    flex-direction: column;
}
.service-booking-sub-container{
    width: 66.5625rem;
    display: flex;
    flex-direction: column;
}
.booking-cards-section{
    display: flex;
    gap: 1.19rem;
    margin-top: 1.62rem;
}
.container-section{
    text-align: start;
    display: flex;
    flex-direction: column;
    gap:0.59rem ;
    width: 100%;
}
.card-title{
    color: #000;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.card-title-ar{
    text-align: end;
}
.confirm-button-section{
    display: flex;
    align-items: center;
    margin-top: 3.37rem;
    justify-content: space-between;
}
.confirm-button-section-ar{
    flex-direction: row-reverse;
}
.button-box{
    display: flex;
    gap: 1.19rem;
}
.button-box-ar{
    flex-direction: row-reverse;
}

@media screen and (max-width:992px){
    .service-booking-sub-container{
        width: 60.5625rem;
    }
}

@media screen and (max-width:768px){
    .service-booking-sub-container{
        width: 40.5625rem;
    }
    .booking-cards-section{
        flex-wrap: wrap;
    }
}

@media screen and (max-width:576px){
    .service-booking-sub-container {
        width: 31.5625rem;
    }
    .confirm-button-section{
        flex-direction: column;
        gap: 1.5rem;
    }
}

