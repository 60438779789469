
.date-filter-main{
    position: relative;
}
.date-filter-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
    width: 10.75rem;
    height: 2.6875rem;
    flex-shrink: 0;
    border-radius: 0.5rem;
    background: #FFF;
    box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.11);
    color: #666;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
}
.date-text-filter{
    color: black;
    border: none !important;
    outline: none;
}

.date-filter-container .arrow-icon {
    display: flex;
    width: 11px;
    height: 11px;
    transition: transform 0.3s ease;
}

.date-filter-container .arrow-icon.open {
    transform: rotate(0deg);
}

.date-filter-container .arrow-icon {
    transform: rotate(180deg);
}

.date-picker-container {
    position: absolute;
    margin-top: 1rem;
    background: white;
    box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.11);
    padding: 1rem;
    border-radius: 0.5rem;
    top: 2rem;
    left: 0rem;
    z-index: 101;
}
    
.date-input-fields {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 100%;
}

.date-input-fields label {
    font-size: 0.9rem;
    color: #333;
}

.date-input-fields input {
    width: 8rem;
    padding: 0.3rem;
    font-size: 0.9rem;
    border: 1px solid #ccc;
    border-radius: 0.3rem;
    width: 100%;
    height: 2rem;
    outline: none;
}

.apply-button,
.reset-button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
}
.button-section{
    display: flex;
    height: 2rem;
    gap: 0.2rem;
    width: 100%;
}
.input-box-container{
    position: relative;
}

.date-placeholder{
    position: absolute;
    border-radius: 0.3rem;
    top: 0.1rem;
    left: 0.1rem;
    width: 85%;
    height: 90%;
    background-color: white;
    display: flex;
    padding-left: 0.2rem;
    color: #666;
    font-size: 1rem;
    font-weight: 400;
}
.date-selected{
    background-color: #108AC6;
    color: #FFF;
    fill: #FFF;
}
