
.radio-tile-2{
    display: flex;
    height: 2.6875rem;
    width: 100%;
    padding: 0.6875rem 1rem 0.6875rem 2.1875rem;
    align-items: start;
    flex-shrink: 0;
    border-radius: 0.5rem;
    background: rgba(165, 225, 255, 0.27);

    color: #000;
    font-size: 0.875rem;
    font-weight: 400;
    cursor: pointer !important;
}
.radio-tile-2.selected{
    background-color:#108AC6 ;
    color: #FFF;
}
.radio-tile-2-ar{
    justify-content: end;
    padding: 0.6875rem 2.1875rem 0.6875rem 1rem;
}