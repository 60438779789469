.already-account-box{
    display: flex;
    gap: 0.2rem;
}
.already-account-box-ar{
    flex-direction: row-reverse;
}
.already-account-box span:nth-child(1){
    color: var(--subtext-color, #666);
    font-size: 0.8125rem;
    font-weight: 400;
}
.already-account-box span:nth-child(2){
    color:#108AC6;
    font-size: 0.8125rem;
    font-weight: 400;
    cursor: pointer;
}
