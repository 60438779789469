.search-container-secondary{
    background: #FFF;
    border: 1px solid #B0B0B0;
}
.search-input-secondary{
    color: #666;
}
.search-input-secondary::placeholder{
    color: #666;
}
.search-button-secondary{
    border: 1px solid #108AC6;
    background: #108AC6;
    color: #FFFFFF;
}