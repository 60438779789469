.text-input-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.62rem;
    text-align: left;
}
.yes-arabic{
    text-align: end;
}
.text-input-container label{
    color: #666;
    font-size: 0.875rem;
    font-weight: 400;
}

.text-input-container input{
    width: 100%;
    height: 2.625rem;
    padding: 1.25rem;
    border-radius: 0.5rem;
    border: 1px solid #B0B0B0;
    outline: none;

    color: #000;
    font-size: 1rem;
    font-weight: 400;
}