.sort-filter-content{
    display: flex;
    gap: 0.56rem;
    border-radius: 0.5rem;
    background: #FFF;
    box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.11);
    padding: 0.5rem 1.56rem 0.69rem 1rem;
    width: 14.25rem;
    height: 2.6875rem;

}
.sort-filter-content svg{
    width: 23px;
    height: 23px;
}
.sort-filter-content span{
    color: #666;
    font-size: 1rem;
    font-weight: 400;
}
.sort-filter-content select{
    color: #000;
    font-size: 1rem;
    font-weight: 400;
    outline: none;
    border: none;
    background-color: inherit;
    width: 5rem;
    cursor: pointer;
}