.user-name-main-box{
    display: flex;
    gap: 1.31rem;
    align-items: center;
    cursor: pointer;
}
.user-icon-box{
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0AF;
}
.user-first-letter{
    font-size: 3.02631rem;
    color: #FFF;
    font-weight: 400;
}
.row-reverse{
    flex-direction: row-reverse;
}
.nav-icon-gap{
    gap: 0.81rem;
}
.user-name-box{
    color: #000;
    font-size: 1rem;
    font-weight: 400;
}

.nav-bar-icon{
    width: 3.4375rem;
    height: 3.4375rem;
}
.nav-bar-icon-fl{
    font-size: 2.25rem;
}


@media screen and (max-width:1200px){
    .nav-bar-icon {
        width: 2.4375rem;
        height: 2.4375rem;
    }
    .nav-bar-icon-fl {
        font-size: 2rem;
    }
}

@media screen and (max-width:576px){
    .notification-svg svg {
        width: 26px;
        height: 26px;
    }
    .nav-bar-icon-fl {
        font-size: 1.5rem;
    }
}